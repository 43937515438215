@import "custom/fonts/fonts";
@import "variables";


/* ###### plugins ####### */

@import "plugins/accordion/accordion";
@import "plugins/amazeui-datetimepicker/css/amazeui.datetimepicker";
@import "plugins/calendar/clndr";
@import "plugins/darggable/jquery-ui-darggable";
@import "plugins/datatable/buttons.bootstrap5";
@import "plugins/datatable/responsive.bootstrap5";
@import "plugins/datatable/css/dataTables.bootstrap5";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fileuploads/css/fileupload";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/gallery/gallery";
@import "plugins/images-comparsion/twentytwenty";
@import "plugins/inputtags/inputtags";
@import "plugins/ion-rangeslider/css/ion.rangeSlider";
@import "plugins/ion-rangeslider/css/ion.rangeSlider.skinSimple";
@import "plugins/jquery-countdown/jquery.countdown";
@import "plugins/jquery-nice-select/css/nice-select";
@import "plugins/jquery-simple-datetimepicker/jquery.simple-dtpicker";
@import "plugins/jquery-steps/jquery.steps";
@import "plugins/jquery-ui-slider/jquery-ui.min";
@import "plugins/jquery-ui-slider/jquery-ui.theme.min";
@import "plugins/jqvmap/jquery-jvectormap-2.0.2";
@import "plugins/leaflet/leaflet";
@import "plugins/morris/morris";
@import "plugins/multislider/multislider";
@import "plugins/notify/css/jquery.growl";
@import "plugins/notify/css/notifIt";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/perfect-scrollbar/perfect-scrollbar";
@import "plugins/pickerjs/picker.min";
@import "plugins/quill/quill.bubble";
@import "plugins/quill/quill.snow";
@import "plugins/ratings-2/star-rating-svg";
@import "plugins/select2/css/select2.min";
@import "plugins/sidebar/sidebar";
@import "plugins/spectrum-colorpicker/spectrum";
@import "plugins/sumoselect/sumoselect";
@import "plugins/summernote/summernote";
@import "plugins/summernote/summernote1";
@import "plugins/sweet-alert/sweetalert";
@import "plugins/telephoneinput/telephoneinput";
@import "plugins/timeline/css/timeline.min";
@import "plugins/treeview/treeview";
@import "plugins/wysiwyag/richtext";
#ui_notifIt {
  position: fixed;
  top: 10px;
  right: 10px;
  cursor: pointer;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  -wekbit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  z-index: 999999;

  &:hover {
    opacity: 1 !important;
  }

  p {
    text-align: center;
    font-size: 14px;
    padding: 0;
    margin: 0;
    padding: 10px;
    font-weight: 400;
    text-transform: capitalize;

    i {
      font-size: 20px;
    }
  }
}

#notifIt_close {
  position: absolute;
  color: #FFF;
  top: 0;
  padding: 0px 5px;
  right: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/* Color setup */
/* You are free to change all of this */

#ui_notifIt {
  &.success {
    background-color: #3bb001;
    color: white;
  }

  &.error {
    background-color: #dc3545;
    color: white;
  }

  &.warning {
    background-color: #ffc107;
  }

  &.info {
    background-color: #3db4ec;
    color: white;
  }

  &.default {
    background-color: #efeff5;
  }

  &.primary {
    background-color: $primary;
    color: #fff;
  }

  &.dark {
    background-color: #4b4261;
    color: #fff;
  }
}

/* notifit confirm */

.notifit_confirm_bg, .notifit_prompt_bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.notifit_confirm, .notifit_prompt {
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  border-radius: 5px;
}

.notifit_confirm_accept, .notifit_confirm_cancel, .notifit_prompt_accept, .notifit_prompt_cancel {
  display: inline-block;
  font-weight: 400;
  color: #160248;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  margin-right: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 3px;
}

.notifit_prompt_accept:hover, .notifit_prompt_cancel:hover {
  background-color: #666;
}

.notifit_confirm_accept {
  background-color: #3bb001;
  color: #fff;
}

.notifit_confirm_cancel {
  background-color: #dc3545;
  color: #fff;
}

.notifit_confirm_message {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.notifit_prompt_message {
  color: #444;
  margin-top: 0;
  text-align: center;
}

.notifit_prompt_input {
  text-align: center;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  outline: none;
  border: 1px solid #AAA;
  color: #444;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.notifit_prompt {
  text-align: center;
}

#ui_notifIt.info {
  margin: 0 auto;
  right: 10px !important;
  left: 10px !important;
}
.morris-hover {
	position: absolute;
	z-index: 10;
  
	&.morris-default-style {
	  border-radius: 10px;
	  padding: 6px;
	  color: #666;
	  background: $white-8;
	  border: solid 1px rgba(230, 230, 230, 0.1);
	  font-family: sans-serif;
	  font-size: 12px;
	  text-align: center;
  
	  .morris-hover-row-label {
		font-weight: 400;
		margin: 0.25em 0;
	  }
  
	  .morris-hover-point {
		white-space: nowrap;
		margin: 0.1em 0;
	  }
	}
  }
  
  #morrisBar8 text {
	font-family: 'Roboto', sans-serif !important;
	font-weight: 400 !important;
	color: #2f4c7d !important;
  }